<ng-container *ngIf="!customPanelTrigger else customPanelTriggerTmpl">
  <aim-icon *ngIf="hasHeaderIcon" [icon]="headerIcon" class="aim-select__header-icon"/>
  <div [attr.id]="_valueId" class="aim-select__value">
    <span *ngIf="empty else selectedValuesTmpl"
          class="aim-select__placeholder aim-select--ellipsis">{{ placeholder }}</span>
  </div>
  <aim-icon [icon]="triggerIcon" class="aim-select__trigger-icon"/>
</ng-container>

<!--셀렉트 패널 커스텀 트리거-->
<ng-template #customPanelTriggerTmpl>
  <ng-content select="[aimSelectPanelTrigger]"/>
</ng-template>

<!--선택 옵션 표시 커스텀 트리거-->
<ng-template #selectedValuesTmpl>
  <span *ngIf="!customTrigger else customTriggerTmpl"
        class="aim-select__value-text aim-select--ellipsis">{{ triggerValue }}</span>
  <ng-template #customTriggerTmpl>
    <ng-content select="aim-select-trigger, [aimSelectTrigger]"/>
  </ng-template>
</ng-template>


<!--셀렉트 패널-->
<ng-template (attach)="_onAttached()"
             (backdropClick)="close()"
             (detach)="close()"
             [cdkConnectedOverlayLockPosition]="false"
             [cdkConnectedOverlayOffsetX]="overlayOffset.x"
             [cdkConnectedOverlayOffsetY]="overlayOffset.y"
             [cdkConnectedOverlayOpen]="panelOpen"
             [cdkConnectedOverlayOrigin]="overlayOrigin"
             [cdkConnectedOverlayPanelClass]="_overlayPanelClass"
             [cdkConnectedOverlayPositions]="_positions"
             [cdkConnectedOverlayScrollStrategy]="_scrollStrategy"
             [cdkConnectedOverlayWidth]="panelWidth"
             cdk-connected-overlay
             cdkConnectedOverlayBackdropClass="aim-select__backdrop"
             cdkConnectedOverlayHasBackdrop
             cdkConnectedOverlayPush>
  <div [class.project-filter]="responsivePanel" [class.table]="isTableContext"
       [ngClass]="customPanelClass" class="aim-select__panel-wrap">
    <!--_MatSelectBase에서 'panel' 템플릿 변수를 사용하므로 제거X-->
    <div #panel
         (@aimTransformPanel.done)="_panelDoneAnimatingStream.next($event.toState)"
         (keydown)="_handleKeydown($event)"
         [@aimTransformPanel]="'showing'"
         [attr.aria-label]="ariaLabel || null"
         [attr.aria-labelledby]="panelAriaLabelledby()"
         [attr.aria-multiselectable]="multiple"
         [attr.id]="id + '-panel'"
         [ngClass]="panelClass"
         class="aim-select__panel {{panelTheme}}"
         role="listbox"
         tabindex="-1">
      <ng-content select="aim-all-select-option"/>
      <ng-content/>
    </div>
  </div>
</ng-template>
