import { Directive, InjectionToken } from '@angular/core';


export const AIM_SELECT_TRIGGER = new InjectionToken<AimSelectTrigger>(' AimSelectTrigger');

/**
 * Allows the user to customize the trigger that is displayed when the select has a value.
 */
@Directive({
  selector: 'aim-select-trigger, [aimSelectTrigger]',
  providers: [{ provide: AIM_SELECT_TRIGGER, useExisting: AimSelectTrigger }]
})
export class AimSelectTrigger {
}


export const AIM_SELECT_PANEL_TRIGGER = new InjectionToken<AimSelectPanelTrigger>(' AimSelectPanelTrigger');

@Directive({
  selector: '[aimSelectPanelTrigger]',
  providers: [{ provide: AIM_SELECT_PANEL_TRIGGER, useExisting: AimSelectPanelTrigger }]
})
export class AimSelectPanelTrigger {
}
