import { AimIconComponent } from '@aimmo/design-system/aim-icon/src';
import { AimOptionModule } from '@aimmo/design-system/aim-option/src';
import { CdkConnectedOverlay, OverlayModule } from '@angular/cdk/overlay';
import { NgClass, NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { AimSelectPanelTrigger, AimSelectTrigger } from './aim-select-trigger.directive';
import { AIM_SELECT_SCROLL_STRATEGY_PROVIDER, AimSelectComponent } from './aim-select.component';


@NgModule({
  declarations: [
    AimSelectComponent,
    AimSelectTrigger,
    AimSelectPanelTrigger,
  ],
  imports: [
    AimIconComponent,
    OverlayModule,
    CdkConnectedOverlay,
    NgClass,
    NgIf,
  ],
  exports: [
    AimSelectComponent,
    AimSelectTrigger,
    AimSelectPanelTrigger,
    AimOptionModule,
  ],
  providers: [AIM_SELECT_SCROLL_STRATEGY_PROVIDER],
})
export class AimSelectModule {
}
