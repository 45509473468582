import { AIM_FORM_LAYOUT, AimFormLayoutControl } from '@aimmo/design-system/aim-form-layout/model';
import { AimFormLayoutComponent } from '@aimmo/design-system/aim-form-layout/src';
import {
  AIM_OPTION_PARENT_COMPONENT,
  AimOptionParentComponent,
  AimOptionParentContextType
} from '@aimmo/design-system/aim-option/model';
import { AimOptgroupComponent, AimOptionComponent } from '@aimmo/design-system/aim-option/src';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';
import { CdkOverlayOrigin, ConnectedPosition, Overlay, ScrollStrategy, ViewportRuler } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import {
  AfterContentInit,
  AfterViewChecked,
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  HostBinding,
  Inject,
  InjectionToken,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Optional,
  QueryList,
  Self,
  SimpleChanges,
} from '@angular/core';
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import {
  _countGroupLabelsBeforeOption,
  _getOptionScrollPosition,
  ErrorStateMatcher,
  MatOptionSelectionChange
} from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { isNil } from 'lodash-es';
import { defer, filter, forkJoin, merge, Observable, startWith, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import {
  AIM_SELECT_CONTEXT,
  AimSelectContext,
  AimSelectFieldSize,
  AimSelectFieldSizeType,
  AimSelectFieldWidth,
  OverlayOffset,
  SelectOptionHeight,
  SelectOptionHeightType
} from '../model';
import { aimSelectAnimations } from './aim-select-animations';
import {
  AIM_SELECT_PANEL_TRIGGER,
  AIM_SELECT_TRIGGER,
  AimSelectPanelTrigger,
  AimSelectTrigger
} from './aim-select-trigger.directive';


let nextUniqueId = 0;

export const AIM_SELECT_SCROLL_STRATEGY = new InjectionToken<() => ScrollStrategy>(
  'aim-select-scroll-strategy',
);

export function AIM_SELECT_SCROLL_STRATEGY_PROVIDER_FACTORY(
  overlay: Overlay,
): () => ScrollStrategy {
  return () => overlay.scrollStrategies.reposition();
}

/** Object that can be used to configure the default options for the select module. */
export interface AimSelectConfig {
  /** Whether option centering should be disabled. */
  disableOptionCentering?: boolean;

  /** Time to wait in milliseconds after the last keystroke before moving focus to an item. */
  typeaheadDebounceInterval?: number;

  /** Class or list of classes to be applied to the menu's overlay panel. */
  overlayPanelClass?: string | string[];
}

export const AIM_SELECT_CONFIG = new InjectionToken<AimSelectConfig>('AIM_SELECT_CONFIG');

export const AIM_SELECT_SCROLL_STRATEGY_PROVIDER = {
  provide: AIM_SELECT_SCROLL_STRATEGY,
  deps: [Overlay],
  useFactory: AIM_SELECT_SCROLL_STRATEGY_PROVIDER_FACTORY,
};


/** Change event object that is emitted when the select value has changed. */
export class AimSelectChange {
  constructor(
    /** Reference to the select that emitted the change event. */
    public source: AimSelectComponent,
    /** Current value of the select that emitted the event. */
    public value: any,
  ) {
  }
}

@Component({
  selector: 'aim-select',
  templateUrl: './aim-select.component.html',
  styleUrls: ['./aim-select.component.scss'],
  exportAs: 'aimSelect',
  inputs: ['disabled', 'disableRipple', 'tabIndex'],
  hostDirectives: [CdkOverlayOrigin],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    role: 'combobox',
    'aria-autocomplete': 'none',
    'aria-haspopup': 'listbox',
    class: 'aim-select aim-select-trigger',
    '[attr.id]': 'id',
    '[attr.tabindex]': 'tabIndex',
    '[attr.aria-owns]': 'panelOpen ? id + "-panel" : null',
    '[attr.aria-controls]': 'panelOpen ? id + "-panel" : null',
    '[attr.aria-expanded]': 'panelOpen',
    '[attr.aria-label]': 'ariaLabel || null',
    '[attr.aria-labelledby]': 'triggerAriaLabelledby',
    '[attr.aria-required]': 'required.toString()',
    '[attr.aria-disabled]': 'disabled.toString()',
    '[attr.aria-invalid]': 'errorState',
    '[attr.aria-activedescendant]': '_getAriaActiveDescendant()',
    '[class.aim-select--disabled]': 'disabled',
    '[class.aim-select--active]': 'panelOpen',
    '[class.aim-select--invalid]': 'errorState',
    '[class.aim-select--required]': 'required',
    '[class.aim-select--empty]': 'empty',
    '[class.aim-select--multiple]': 'multiple',
    '[class.table]': 'isTableContext',
    '[class.fit-content]': '!!customPanelTrigger',
    '(keydown)': '_handleKeydown($event)',
    '(click)': 'toggle()',
    '(focus)': '_onFocus()',
    '(blur)': '_onBlur()',
  },
  animations: [aimSelectAnimations.aimTransformPanel],
  providers: [
    { provide: AimFormLayoutControl, useExisting: AimSelectComponent },
    { provide: AIM_OPTION_PARENT_COMPONENT, useExisting: AimSelectComponent },
  ]
})
export class AimSelectComponent extends MatSelect implements AimOptionParentComponent, OnInit, OnChanges, AfterContentInit, AfterViewChecked {
  public override readonly controlType: string = 'aim-select';
  public readonly $event: KeyboardEvent;
  public readonly selectSizeType = AimSelectFieldSize;
  public readonly overlayOffset: OverlayOffset = { x: 0, y: 0 };
  public override readonly optionSelectionChanges: Observable<MatOptionSelectionChange> = this.optionSelectionChangesAction();
  public triggerRect: DOMRect;
  public triggerAriaLabelledby: string | null = null;
  // tslint:disable-next-line:variable-name
  public override _positions: ConnectedPosition[] = [
    { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
    { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
    { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' }
  ];
  // tslint:disable-next-line:variable-name
  public override _onChange = ((value: any) => {
    return new AimSelectChange(this, value);
  });
  @Input() public responsivePanel = false;
  @Input() public customPanelClass?: string;
  @Input('aria-labelledby') public override ariaLabelledby = '';
  @ContentChild(AIM_SELECT_PANEL_TRIGGER) public customPanelTrigger: AimSelectPanelTrigger;
  @ContentChild(AIM_SELECT_TRIGGER) public override customTrigger: AimSelectTrigger;
  @ContentChildren(AimOptionComponent, { descendants: true }) public override options: QueryList<AimOptionComponent>;
  @ContentChildren(AimOptgroupComponent, { descendants: true }) public override optionGroups: QueryList<AimOptgroupComponent>;
  protected readonly viewportChangeThrottleTime = 100;
  private isSizeChanged = false;
  private selectHeaderIcon = '';
  private selectSize: AimSelectFieldSizeType = this.selectSizeType.fill;
  private parentContext: AimOptionParentContextType = AimSelectContext.normal;
  private fixedOptionHeight: SelectOptionHeightType | null = null;
  private updateSource = new Subject<void>();

  constructor(
    public readonly overlayOrigin: CdkOverlayOrigin,
    @Optional() @Self() @Inject(AIM_SELECT_CONTEXT) private selectContext: AimSelectContext,
    @Optional() @Inject(DOCUMENT) private document: any,
    protected viewportRuler: ViewportRuler,
    protected changeDetectorRef: ChangeDetectorRef,
    protected ngZone: NgZone,
    public defaultErrorStateMatcher: ErrorStateMatcher,
    public elementRef: ElementRef<HTMLElement>,
    @Optional() private dir: Directionality,
    @Optional() public parentForm: NgForm,
    @Optional() public parentFormGroup: FormGroupDirective,
    @Optional() @Inject(AIM_FORM_LAYOUT) protected parentFormLayout: AimFormLayoutComponent,
    @Self() @Optional() private ngCtrl: NgControl,
    @Attribute('tabindex') public tabIdx: string,
    @Inject(AIM_SELECT_SCROLL_STRATEGY) public scrollStrategyFactory: () => ScrollStrategy,
    private liveAnnouncer: LiveAnnouncer,
    @Optional() @Inject(AIM_SELECT_CONFIG) private defaultOptions?: AimSelectConfig,
  ) {
    super(
      viewportRuler,
      changeDetectorRef,
      ngZone,
      defaultErrorStateMatcher,
      elementRef,
      dir,
      parentForm,
      parentFormGroup,
      undefined,
      ngCtrl,
      tabIdx,
      scrollStrategyFactory,
      liveAnnouncer,
      defaultOptions
    );
    this.initializeStatus();
  }

  @Input()
  public get size(): AimSelectFieldSizeType {
    return this.selectSize;
  }

  public set size(value: AimSelectFieldSizeType) {
    if (value !== this.selectSize) {
      this.selectSize = value;
      this.stateChanges.next(undefined);
    }
  }

  @Input()
  public get headerIcon(): string {
    return this.selectHeaderIcon;
  }

  public set headerIcon(value: string) {
    this.selectHeaderIcon = value;
    this.stateChanges.next(undefined);
  }

  @HostBinding('style.--select-field-width')
  public get selectFieldWidth(): AimSelectFieldWidth {
    return this.context !== AimSelectContext.normal ? null : AimSelectFieldWidth[this.size];
  }

  public get isTableContext(): boolean {
    return this.context === AimSelectContext.table;
  }

  public get hasHeaderIcon(): boolean {
    return !!(this.headerIcon);
  }

  public get triggerIcon(): string {
    return this.panelOpen ? 'chevron-up' : 'chevron-down';
  }

  // TODO: 테마 변경이 필요한 경우 구현.
  public get panelTheme(): string {
    return '';
  }

  public get context(): AimOptionParentContextType {
    return this.parentContext;
  }

  protected get optionHeight(): number {
    return this.fixedOptionHeight || this.triggerRect?.height;
  }

  /** TODO: 뷰포트에 따라 패널 크기 조정 필요하면 구현, 변경 중 이벤트 다량 발생 처리 필요할 수도... */
  protected get viewportChangeAction$(): Observable<Event> {
    return this.viewportRuler.change(this.viewportChangeThrottleTime).pipe(
      filter(() => this.panelOpen),
      tap(() => {
        this.triggerRect = this.getTriggerRect();
        this.changeDetectorRef.markForCheck();
        this.stateChanges.next(undefined);
      })
    );
  }

  private get updateAction$(): Observable<void> {
    return merge(this._openedStream, this.updateSource).pipe(
      tap(() => this._scrollOptionIntoView(this._keyManager.activeItemIndex || 0))
    );
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.initTriggerAriaLabelledby();
    forkJoin([
      this.viewportChangeAction$,
      this.updateAction$
    ]).pipe(takeUntil(this._destroy)).subscribe();
  }

  public override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.size) {
      this.isSizeChanged = changes.size.previousValue !== changes.size.currentValue;
    }
  }

  public override ngAfterContentInit(): void {
    super.ngAfterContentInit();

    if (!!this.customPanelTrigger) {
      this.fixedOptionHeight = SelectOptionHeight[this.context];
    }
  }

  public ngAfterViewChecked(): void {
    if (this.panelOpen && this.isSizeChanged) {
      this.isSizeChanged = false;
      this.triggerRect = this.getTriggerRect();
      this.changeDetectorRef.detectChanges();
      this.updateSource.next();
    } else {
      this.isSizeChanged = false;
    }
    if (!!this.customPanelTrigger) {
      const size = this.size !== AimSelectFieldSize.fill ? this.size : AimSelectFieldSize.small;
      this.panelWidth = parseInt(AimSelectFieldWidth[size], 10);
    } else {
      this.panelWidth = this.triggerRect?.width;
    }
  }

  // TODO: 수정 이유 이력 추적 중 by Rex
  //  https://app.asana.com/0/1179494858925986/1203831553868817/f
  public initTriggerAriaLabelledby(): void {
    this.triggerAriaLabelledby = this.ariaLabel ? null
      : `${this.parentFormLayout?.labelId || ''} ${this._valueId} ${this.ariaLabelledby}`.trim();
    this.changeDetectorRef.detectChanges();
  }

  public panelAriaLabelledby(): string | null {
    if (this.ariaLabel) {
      return null;
    }

    const labelId = this.parentFormLayout?.labelId;
    return this.ariaLabelledby ? `${labelId} ${this.ariaLabelledby}`.trim() : labelId;
  }

  public override open(): void {
    this.triggerRect = this.getTriggerRect();
    super.open();
    if (this.empty) {
      this._keyManager?.setActiveItem(-1);
    }
  }

  public override close(): void {
    super.close();
  }

  public override _scrollOptionIntoView(index: number): void {
    const option = this.options.toArray()[index];
    if (!option) {
      return undefined;
    }

    const panel: HTMLElement = this.panel.nativeElement;
    const labelCount = _countGroupLabelsBeforeOption(index, this.options, this.optionGroups);
    const optionHeight = this.optionHeight;

    if (index === 0 && labelCount === 1) {
      panel.scrollTop = 0;
    } else {
      panel.scrollTop = _getOptionScrollPosition(
        (index + labelCount) * optionHeight,
        optionHeight,
        panel.scrollTop,
        panel.offsetHeight,
      );
    }
  }

  protected initializeStatus(): void {
    this.id = `aim-select-${nextUniqueId++}`;
    this._valueId = `aim-select-value-${nextUniqueId++}`;

    /** Disable move active option above trigger to center. */
    this.disableOptionCentering = true;

    /** Disable Ripple */
    this.disableRipple = true;

    /** Set Context */
    this.parentContext = this.selectContext?.type || AimSelectContext.normal;
  }

  protected getTriggerRect(): DOMRect {
    return this.elementRef.nativeElement.getBoundingClientRect();
  }

  private optionSelectionChangesAction(): Observable<MatOptionSelectionChange> {
    return defer(() => {
      const options = this.options;

      if (!isNil(options) && options.length !== 0) {
        return options.changes.pipe(
          startWith(options),
          switchMap(() => merge(...options.map(option => option.onSelectionChange))),
          takeUntil(options.changes)
        );
      }

      return this.ngZone.onStable.pipe(
        take(1),
        switchMap(() => this.optionSelectionChanges)
      );
    }) as Observable<MatOptionSelectionChange>;
  }
}
